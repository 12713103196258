import React from 'react';

const RelaxProcess = ({ url = 'assets/img/Personaje_Welli_Gris-08.png' }) => {
  return (
    <div
      className='relative w-full shrink-0 h-full px-8  pt-16 md:pt-24 flex flex-col items-center  justify-between'
      style={{
        background:
          'linear-gradient(90deg, rgba(76,125,255,1) 0%, rgba(254,109,128,1) 100%)',
      }}
    >
      <img
        src={'assets/img/sellos-09.png'}
        alt='Welli postura'
        className=' h-[30%] w-full md:w-4/5 object-contain  '
        draggable='false'
      />
      <img
        src={url}
        alt='Welli postura'
        className=' h-[70%] object-scale-down object-bottom'
        draggable='false'
      />
    </div>
  );
};

export default RelaxProcess;
