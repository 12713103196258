import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Offer from './Offer';
import Sign from './Sign';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux/es/hooks/useSelector';
import store from '../store';
import { selectActiveStep, handleNext } from '../store/applicationStep';
import useApplicationSteps from '../hooks/useApplicationSteps';

import { Typography } from '@mui/material';
import CreateProfile from '../features/CreateProfile/pages';
import CreateApplication from '../features/CreateApplication';

const theme = createTheme();

export default function ApplicationManager() {
  useApplicationSteps();
  const navigate = useNavigate();
  const step = useSelector(selectActiveStep);

  const nextStep = () => {
    store.dispatch(handleNext(step));
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CreateProfile formCompleted={nextStep} />;
      case 1:
        return <CreateApplication formCompleted={nextStep} />;
      case 2:
        return <Offer formCompleted={nextStep} />;
      case 3:
        return <Sign formCompleted={gotoLogin} />;
      case 4:
        gotoLogin();
        break;
      case 9:
        return (
          <Typography
            component='h1'
            variant='h4'
            align='center'
            color='text.primary'
            gutterBottom
          >
            Validando...
          </Typography>
        );

      default:
        throw new Error('Unknown step');
    }
  }

  const gotoLogin = () => {
    setTimeout(() => {
      navigate('/applast');
    }, 100);
  };

  return (
    <ThemeProvider theme={theme}>
      {getStepContent(step)}

    </ThemeProvider>
  );
}
