import { LinearProgress } from '@mui/material';
import React from 'react';

const AppInProcess = () => {
  return (
    <article className='w-80 md:w-96'>
      <div className='flex justify-center'>
        <img
          src={'assets/img/Reloj_1_WELLI.png'}
          alt='Welli postura'
          className=' w-24 mb-6 '
        />
      </div>
      <section className='mb-6'>
        <p className='text-lg font-bold mb-4 text-center'>
          No actualices la pantalla
        </p>
      </section>
      <p className='mb-4  text-center'>
        Estamos estudiando tu solicitud y en un instante te daremos una
        respuesta.
      </p>
      <p className='mb-4 text-center'>¡Gracias por utilizar Welli!</p>
      <div className='mb-4'>
        <LinearProgress />
      </div>
    </article>
  );
};

export default AppInProcess;
