import { Typography } from '@mui/material';
import React from 'react';
import Btn from '../../../Components/common/Btn';
import { useNavigate } from 'react-router-dom';

function SendMessageFail({ phone }) {
  const navigate = useNavigate();
  return (
    <section className='flex flex-col items-center'>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyCcontent: 'center',
        }}
        className='mb-8'
      >
        <img
          className='w-6 h-6 mr-2 rounded-full object-cover object-center'
          src='/assets/img/Flag_Colombia.jpg'
        />
        <span className='font-bold text-lg'>{phone}</span>
      </div>
      <>
        <Typography
          variant='h5'
          fontSize={18}
          fontWeight={500}
          align='center'
          sx={{ mb: '20px' }}
          gutterBottom
        >
          <span className='text-gray-900'>
            El celular aún no se ha registrado.
          
          </span>
        </Typography>
        <Btn
          onClick={() => navigate('/newlogin')}
          color='danger'
          className='relative w-80 mb-4'
        >
          crear una cuenta.
        
        </Btn>
      </>
    </section>
  );
}

export default SendMessageFail;
