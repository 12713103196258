import { useEffect, useState } from "react";
import { useDebounced } from "./useDebounced";
import { searchProcedures } from "../api/procedures";

const OtherProcedure = {
    id: "",
    name: "Otro",
    speciality_name: "Otro",
};

export const useProceduresDebounced = (input, maxLengthText = 3) => {
    const debouncedValue = useDebounced(input, 800);
    const [loadingProcedures, setLoadingProcedures] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const getProcedures = async () => {
            if (debouncedValue && debouncedValue.length > maxLengthText) {
                setOptions([]);
                setLoadingProcedures(true);

                try {
                    const resp = await searchProcedures(debouncedValue, {
                        signal: controller.signal,
                    });
                    const data = resp.data.data || []; // Asegura que siempre sea un array
                    
                    // Garantiza que siempre esté OtherProcedure
                    setOptions([...data, OtherProcedure]);
                } catch (error) {
                    console.log("Request canceled");
                } finally {
                    setLoadingProcedures(false);
                }
            } else {
                setOptions([]);
            }
        };

        getProcedures();

        return () => controller.abort();
    }, [debouncedValue, maxLengthText]);

    return { loadingProcedures, options };
};
