import { Typography } from '@mui/material';
import React from 'react';
import Btn from '../../../Components/common/Btn';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ReplayIcon from '@mui/icons-material/Replay';
function InfoModal({
  phone,
  onContinue,
  onCancel,
  fetching,
  fetchingLogin,
  onLogin,
  type,
}) {
  return (
    <section className='flex flex-col items-center'>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyCcontent: 'center',
        }}
        className='mb-8'
      >
        <img
          className='w-6 h-6 mr-2 rounded-full object-cover object-center'
          src='/assets/img/Flag_Colombia.jpg'
        />
        <span className='font-bold text-lg'>+57 {phone}</span>
      </div>
      {fetching !== 'error' && (
        <>
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight={500}
            align='center'
            sx={{ mb: '20px' }}
            gutterBottom
          >
            <span className='text-gray-900'>¿Es este número correcto? </span>
          </Typography>
          <Btn
            onClick={onContinue}
            color='secondary'
            className='relative w-80 mb-4'
            disabled={fetching === 'loading'}
          >
            Continuar
            <KeyboardBackspaceIcon
              sx={{ fontSize: 30 }}
              className=' absolute top-[20%] right-3 rotate-180'
            />
          </Btn>

          <p
           onClick={onCancel}
            className='w-full text-slate-900 text-center cursor-pointer  font-bold '
          >
            Volver y editar número
          </p>
        </>
      )}

      {fetching === 'error' && type != 'continue' && (
        <section className='flex flex-col'>
          <Typography
            variant='h5'
            fontSize={18}
            fontWeight={500}
            align='center'
            sx={{ mb: '20px' }}
            gutterBottom
          >
            <span className='text-slate-800'>
              Este número ya está asociado <br /> a una cuenta registrada.
            </span>
          </Typography>
          <Btn
            onClick={onLogin}
            color='secondary'
            className='relative w-80 mb-4'
            disabled={fetchingLogin === 'loading'}
          >
            Continuar con mi crédito
            <ReplayIcon
              sx={{ fontSize: 30 }}
              className=' absolute top-[20%] right-3 '
            />
          </Btn>
          <p
           onClick={onCancel}
            className='w-full text-slate-900 text-center cursor-pointer  font-bold '
          >
            Volver y editar número
          </p>
        </section>
      )}
      
    </section>
  );
}

export default InfoModal;
