import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../../Components/common/Btn';
import './authOtp.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Modal from '../../../Components/common/Modal/Modal';
import SendMessageFail from '../components/SendMessageFail';
import {
  reeSendOtpValidationAccount,
  sendOptLogin,
  useLogin,
  validateAccount,
} from '../../../hooks/useOTP';
import RejectedMessage from '../components/RejectedMessage';
import InProgress from '../components/InProgress';
import clsx from 'clsx';

const theme = createTheme();
const TIMER_SECONDS = 30;
const AuthOpt = () => {
  const [searchParams] = useSearchParams();
  const [erroAuth, setErroAuth] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [phone, setPhone] = useState('');
  const [isLogin, setisLogin] = useState(false);
  const [timeLeft, setTimeLeft] = useState(); // Tiempo inicial en segundos
  const timerRef = useRef(null); // Referencia para el temporizador

  const { login, inValidationApp, showRejectedModal, showFailCodes } =
    useLogin(navigate);

  const { sendOptValAccount } = reeSendOtpValidationAccount(navigate);
  const { fetchingValidateAccont, handleValidation } =
    validateAccount(navigate);

  useEffect(() => {
    const _phone = sessionStorage.getItem('celular');
    setisLogin(searchParams.get('login') == 'true');
    startTimer();

    if (!_phone) {
      navigate('/login');
    }
    setPhone(_phone);

    return () => {
      // Limpia el temporizador al desmontar el componente
      clearInterval(timerRef.current);
    };
  }, []);

  const onSubmit = () => {
    if (searchParams.get('login')) {
      login(phone, getValues('codes'));
    } else {
      const _phone = phone.includes('57') ? phone : `57${phone}`;
      handleValidation({ phone: _phone, otp: getValues('codes') });
    }
  };

  const reSendOtp = async () => {
    startTimer();
    const _phone = phone.includes('57') ? phone : `57${phone}`;

    if (isLogin) {
      const resp = await sendOptLogin({
        phone: _phone,
      });

      if (!resp) {
        setErroAuth(true);
      }
    } else {
      sendOptValAccount(_phone);
    }
  };

  const startTimer = () => {
    setTimeLeft(TIMER_SECONDS);
    // Limpia cualquier temporizador existente
    clearInterval(timerRef.current);

    // Configura un nuevo temporizador
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Detén el temporizador cuando llegue a 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <ThemeProvider theme={theme}>
      <section className='h-full overflow-x-hidden w-full flex  flex-col items-center  bg-[#EDEEF8]'>
        <div className='animate-fadeIn  flex w-full md:w-[26rem]  flex-col  items-center gap-8 md:gap-10 px-12 pt-10'>
          <img
            src={'assets/img/Welli_Logo-06.jpg'}
            alt='Welli postura'
            className='w-32 '
          />

          <section className='text-center text-lg font-bold w-full '>
            <span>
              Ingresa el código de 4 dígitos <br /> enviado al número <br />
            </span>
            <span className='block text-slate-700 mt-4'>{phone.slice(2)}</span>
            <div className='mt-4 border-b-2 w-full border-gray-300'></div>
          </section>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col gap-8 md:gap-12'
          >
            <section>
              <MuiOtpInput
                className={clsx(
                  'w-full otpInput ',
                  (showFailCodes || fetchingValidateAccont == 'rejected') &&
                    'danger'
                )}
                error
                slotProps={{
                  root: { className: 'flex justify-between' }, // Estilos para el contenedor raíz
                  input: {
                    className:
                      'border-red-500 focus:bg-green-500 rounded-md text-center w-12',
                  }, // Estilos para los campos de entrada individuales
                }}
                length={4}
                {...register('codes', {
                  required: 'Este campo es requerido',
                  maxLength: {
                    value: 4,
                    message: 'El código debe tener 4 dígitos',
                  },
                  minLength: {
                    value: 4,
                    message: 'El código debe tener 4 dígitos',
                  },
                })}
                value={watch('codes')}
                onChange={(value) => setValue('codes', value)}
              />

              {errors.codes && (
                <p className='text-red-500 mt-4'>{errors.codes.message}</p>
              )}

              {(showFailCodes || fetchingValidateAccont == 'rejected') && (
                <p className='text-red-500 mt-4'>
                  Código inválido, por favor vuelve a intentarlo.
                </p>
              )}
            </section>
            <Button
              type='submit'
              variant='contained'
              className='relative'
              color='secondary'
            >
              Validar y Continuar
              <KeyboardBackspaceIcon
                sx={{ fontSize: 30 }}
                className=' absolute top-[20%]  right-3 rotate-180 '
              />
            </Button>
          </form>
        </div>

        <section className='animate-fadeIn-text text-center text-lg font-bold mt-8'>
          ¿No recibiste código?{' '}
          {timeLeft > 0 ? (
            <p className='text-primary-500 text-sm'>
              {timeLeft} para solicitar el código de nuevo
            </p>
          ) : (
            <span
              onClick={reSendOtp}
              className='underline underline-offset-1 cursor-pointer'
            >
              Enviar de nuevo
            </span>
          )}
          <p
            onClick={() => navigate('/create_account')}
            className='w-full mb-8 text-slate-900 text-center cursor-pointer  font-bold mt-4 '
          >
            Volver y editar número
          </p>
        </section>
      </section>
      <Modal showClose={false} open={erroAuth}>
        <SendMessageFail phone={phone} />
      </Modal>

      <Modal showClose={false} open={showRejectedModal}>
        <RejectedMessage logout={() => navigate('/newlogin')} />
      </Modal>
      <Modal showClose={false} open={inValidationApp}>
        <InProgress logout={() => navigate('/newlogin')} />
      </Modal>
    </ThemeProvider>
  );
};

export default AuthOpt;
