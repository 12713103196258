import React from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Btn from '../../../Components/common/Btn';

const Actions = ({ handleGoBack, handleContinue }) => {
  return (
    <div className='w-full'>
      <Btn
        type='submit'
        onClick={() => handleContinue && handleContinue()}
        color='secondary'
        className=' w-full mb-4 '
      >
        <span className='w-[90%]'>Continuar</span>
        <KeyboardBackspaceIcon
          sx={{ fontSize: 30 }}
          className='right-3 rotate-180 '
        />
      </Btn>

      <p
        onClick={() => handleGoBack()}
        className='w-full text-slate-900 text-center cursor-pointer  font-bold '
      >
        Volver
      </p>
    </div>
  );
};

export default Actions;
