/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Button } from '@mui/base/Button';
import { Popper } from '@mui/base/Popper';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';

const Autocomplete = React.forwardRef(function Autocomplete(props, ref) {
  const {
    disableClearable = false,
    disabled = false,
    readOnly = false,
    options,
    isOptionEqualToValue,
    inputProps,
    getOptionLabel = (option) => option.label,
    inputRef,
    error,
    helperText,
    placeholder,
    ...other
  } = props;

  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getClearProps,
    getListboxProps,
    getOptionProps,
    dirty,
    id,
    popupOpen,
    focused,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    ...props,
    componentName: 'BaseAutocompleteIntroduction',
  });

  const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;

  const rootRef = useForkRef(ref, setAnchorEl);

  return (
    <React.Fragment>
      <section className='w-full relative'>
        <div
          {...getRootProps(other)}
          ref={rootRef}
          className={clsx(
            'flex relative w-full items-center border rounded-xl overflow-hidden',
            'border-solid  ',
            'shadow-sm shadow-slate-200 focus-within:shadow-md ',
            ' bg-white text-primary-900',
            `${
              error
                ? 'border-red-500 focus-within:shadow-red-500/50 focus-within:border-red-500 hover:border-red-500'
                : 'border-slate-400 focus-within:shadow-primary-500/50 focus-within:border-primary-500 hover:border-primary-600'
            }`
          )}
        >
          <input
            id={id}
            disabled={disabled}
            readOnly={readOnly}
            className={clsx(
              'w-full text-sm font-normal font-sans leading-5 px-4 py-3',
              'focus-visible:outline-none '
            )}
            {...getInputProps()}
            placeholder={placeholder}
          />
          {hasClearIcon && (
            <Button
              {...getClearProps()}
              className='self-center outline-0 shadow-none border-0 py-0 px-0.5 rounded-[4px] bg-transparent hover:bg-violet-100 dark:hover:bg-gray-700 hover:cursor-pointer'
            >
              <ClearIcon className='translate-y-[2px] scale-90' />
            </Button>
          )}
          <Button
            {...getPopupIndicatorProps()}
            className='self-center outline-0 shadow-none border-0 py-0 px-0.5 rounded-[4px] bg-transparent hover:bg-violet-100 dark:hover:bg-gray-700 hover:cursor-pointer'
          ></Button>
          <ArrowDropDownIcon
            className={clsx('translate-y-[2px]', popupOpen && 'rotate-180')}
          />
        </div>
        {anchorEl && (
          <Popper
            open={popupOpen}
            anchorEl={anchorEl}
            style={{ width: anchorEl?.offsetWidth || '100%' }} // Ancho dinámico
            slotProps={{
              root: {
                className: 'relative z-[1001] w-full', // z-index: 1001 is needed to override ComponentPageTabs with z-index: 1000
              },
            }}
            modifiers={[
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport', // Cambia esto si necesitas que respete un contenedor padre
                },
              },
              {
                name: 'flip',
                enabled: true,
              },
            ]}
          >
            <ul
              {...getListboxProps()}
              className='text-sm box-border p-1.5 my-1 mx-0 min-w-[320px] rounded-xl overflow-auto outline-0 max-h-[300px] z-[1] bg-white dark:bg-gray-800 border border-solid border-gray-200 dark:border-gray-900 text-gray-900 dark:text-gray-200 shadow-[0_4px_30px_transparent] shadow-gray-200 dark:shadow-gray-900'
            >
              {options.map((option, index) => {
                const optionProps = getOptionProps({ option, index });

                return (
                  <li
                    key={index}
                    {...optionProps}
                    className='list-none p-2 rounded-lg cursor-default last-of-type:border-b-0 hover:cursor-pointer aria-selected:bg-violet-100 dark:aria-selected:bg-violet-900 aria-selected:text-violet-900 dark:aria-selected:text-violet-100 ui-focused:bg-gray-100 dark:ui-focused:bg-gray-700 ui-focus-visible:bg-gray-100 dark:ui-focus-visible:bg-gray-800 ui-focused:text-gray-900 dark:ui-focused:text-gray-300 ui-focus-visible:text-gray-900 dark:ui-focus-visible:text-gray-300 ui-focus-visible:shadow-[0_0_0_3px_transparent] ui-focus-visible:shadow-violet-200 dark:ui-focus-visible:shadow-violet-500 ui-focused:aria-selected:bg-violet-100 dark:ui-focused:aria-selected:bg-violet-900 ui-focus-visible:aria-selected:bg-violet-100 dark:ui-focus-visible:aria-selected:bg-violet-900 ui-focused:aria-selected:text-violet-900 dark:ui-focused:aria-selected:text-violet-100 ui-focus-visible:aria-selected:text-violet-900 dark:ui-focus-visible:aria-selected:text-violet-100'
                  >
                    {getOptionLabel(option)}
                  </li>
                );
              })}

              {options.length === 0 && (
                <li className='list-none p-2 cursor-default'>Sin resultados</li>
              )}
            </ul>
          </Popper>
        )}
      </section>
    </React.Fragment>
  );
});
export default Autocomplete;
