import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearSteps } from '../hooks/useApplicationSteps';
import Btn from '../Components/common/Btn';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { currencyFormat } from '../helpers/formaHelper';

export default function Confirm() {
  const navigate = useNavigate();
  const [contractData, setContractData] = useState({
    referencia_pago: '',
    monto: '',
  });

  useEffect(() => {
    const credito = sessionStorage.getItem('referencia_pago');
    const monto = sessionStorage.getItem('monto');

    if (monto && credito) {
      setContractData({
        referencia_pago: credito,
        monto: monto,
      });
      return;
    }

    const profileId = sessionStorage.getItem('profile_id');
    axios
      .get(
        `${process.env.REACT_APP_WELLI_API}/api/applications/risk/${profileId}`
      )
      .then((resp) => {
        setContractData({
          referencia_pago: resp.data.app.referencia_pago,
          monto: resp.data.risk.monto,
        });
      })
      .catch(() => {});
  }, []);
  const logout = () => {
    sessionStorage.clear();
    clearSteps();
    navigate('/newlogin');
  };

  return (
    <section className='h-screen overflow-hidden w-full flex  flex-col content-center items-center justify-center bg-[#EDEEF8]'>
      <section className='h-screen overflow-hidden w-full flex  flex-col  items-center px-6 py-8  md:px-12 md:pt-10 md:w-[34rem] '>
        <img
          src={'assets/img/check-welli-icon.png'}
          alt='Welli postura'
          className='w-24 mb-6 '
        />
        <h3 className='font-bold text-2xl text-center mb-8'>
          Crédito confirmado
        </h3>
        <p className='text-xl mb-6'>
          Tu crédito {contractData.referencia_pago} por $
          {currencyFormat(contractData.monto)} ha sido{' '}
          <strong>aprobado y firmado</strong>. Te enviamos una copia del
          contrato a tu correo.
        </p>
        <p className='text-xl'>
          📷📲 Para proceder con el procedimiento médico, envíanos una foto de
          tu documento de identidad (ambos lados) por{' '}
          <a
            href='https://api.whatsapp.com/send?phone=573105240287'
            rel='noreferrer'
            className='text-primary-500 underline font-medium'
            target='_blank'
          >
            WhatsApp.
          </a>
          
        </p>

        <Btn
          color='secondary'
          className='w-full mt-12'
          type='submit'
          onClick={logout}
        >
          Ingresar a mi cuenta
        </Btn>
      </section>
    </section>
  );
}
