export const ENCONOMIC_ACTIVITY = [
  { key: 'Empleado', value: 'Empleado' },
  { key: 'Independiente', value: 'Independiente' },
  { key: 'Pensionado', value: 'Pensionado' },
];

export const ID_TIPOS = [
  {
    value: '1',
    key: 'Cédula de ciudadanía',
  },
];
