import { Typography } from '@mui/material';
import React from 'react';

const FastProcess = ({ url = 'assets/img/Welli_Logo-06.jpg' }) => {
  return (
    <div
      className=' w-full shrink-0 h-full px-8 pt-10 flex flex-col items-center justify-center'
      style={{
        background:
          'linear-gradient(90deg, rgba(76,125,255,1)  0%,  rgba(5,213,189,1) 100%)',
      }}
    >
      <img
        src={url}
        alt='Welli postura'
        className='h-[45%] md:h[65%] object-contain mb-10'
        draggable='false'
      />
      <Typography
        variant='h4'
        fontSize={28}
        fontWeight={500}
        color={'white'}
        gutterBottom
        className=''
        style={{ wordWrap: 'break-word' }}
      >
        Financia tu procedimiento médico{' '}
        <span className='underline '>en menos de 5 minutos</span>
      </Typography>
    </div>
  );
};

export default FastProcess;
