import React from 'react';
import Button from '../../../Components/common/Btn';
import { Link } from 'react-router-dom';

const CreateContinueApp = ({ onSelected }) => {
  return (
    <section className='px-6 py-2 text-center gap-6 flex flex-col'>
      <Button
        onClick={() => onSelected('new')}
        size='medium'
        color='white'
        className='w-full '
      >
        Solicitar un crédito
      </Button>
      <Button
        variant='outlined'
        size='medium'
        color='white'
        className='w-full bg-transparent'
        onClick={() => onSelected('continue')}
      >
        Retomar mi solicitud
      </Button>
      <div>
        <Link
          to='/newlogin'
          className=' text-slate-900 text-center underline underline-offset-4'
        >
          Ingresar a mi cuenta
        </Link>
      </div>
    </section>
  );
};

export default CreateContinueApp;
