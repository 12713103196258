import * as React from "react";
import Typography from "@mui/material/Typography";

  export default function BodyRejectedComponent () {

 return <>
    <Typography
      variant="body1"
      color="text.secondary"
      align="left"
      gutterBottom
      sx={{ marginBottom: "20px" }}
    >
      Lamentamos informarte que después de una cuidadosa evaluación, no pudimos
      aprobar tu solicitud de crédito.
    </Typography>
    <Typography
      variant="body1"
      color="text.secondary"
      align="left"
      gutterBottom
      sx={{ marginBottom: "20px" }}
    >
      Algunos factores que tenemos en cuenta al tomar la decisión son: <br />
      Resultados de tu situación financiera actual <br />
      Edad entre 18 y 70 años <br />
      Antigüedad laboral mínima de seis meses. <br />
    </Typography>
    <Typography
      variant="body1"
      color="text.secondary"
      align="left"
      gutterBottom
      sx={{ marginBottom: "20px" }}
    >
      Te hemos enviado un correo con información adicional para brindarte más
      detalles al respecto.
    </Typography>
  </>
}

