import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCreateApp = createAsyncThunk(
  'applications/fetchCreateApp',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WELLI_API}/api/profile_minimal`,
        body
      );
      return response.data; // Devuelve los datos directamente
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    }
  }
);

const initialState = {
  formData: {
    actividad_economica: 'Empleado',
    nombres: '',
    apellidos: '',
    email: '',
    email2: '',
    telefono_contacto:''
  },
  apiRequest: {
    fetching: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
    error: null,
    apiData: null,
  },
};

const createProfileSlice = createSlice({
  name: 'createProfile',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setData:  (state, { payload }) => {
      Object.entries(payload).forEach(([key, value]) => {
        state.formData[key] = value;
      });
    },
   
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCreateApp.pending, (state) => {
      state.apiRequest.fetching = 'pending';
      state.apiRequest.error = null;
    })
    .addCase(fetchCreateApp.fulfilled, (state, action) => {
      state.apiRequest.fetching = 'succeeded';
      state.apiRequest.apiData = action.payload;
    })
    .addCase(fetchCreateApp.rejected, (state, action) => {
      state.apiRequest.fetching = 'failed';
      state.apiRequest.error = action.payload || 'Unknown error';
    });
  },
});

export const { reset, setData } = createProfileSlice.actions;
export default createProfileSlice.reducer;

export const selectFormData = (state) => state.createProfile.formData;
export const selectFetching = (state) => state.createProfile.apiRequest;
export const selectError = (state) => state.createProfile.error;
