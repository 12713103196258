import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
export const Select = ({
  placeholder,
  valueInitial,
  onChange,
  options,
  error,
  helperText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(valueInitial);

  const handleSelect = (option) => {
    setSelected(option);
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelected(valueInitial);
  }, [valueInitial]);

  return (
    <div className='relative w-full '>
      <div
        className={clsx(
          'text-sm font-normal font-sans leading-5 px-4 py-3 cursor-pointer bg-white rounded-xl text-left border border-solid ',
          ' transition-all hover:bg-slate-50 outline-0 shadow-md shadow-slate-100 d ',
          `${
            error
              ? 'border-red-500  hover:border-red-500'
              : 'border-slate-400  hover:border-primary-600'
          }`,
          `${isOpen && !error && 'shadow-primary-500/50'}`,
          `${isOpen && error && ' shadow-red-500/50 '}`,
          `${selected ? 'text-slate-900' : 'text-slate-400 '}`
        )}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        { options.find(o=>o.value == selected)?.key || placeholder}
      </div>
      {isOpen && (
        <ul
          className={clsx(
            'absolute z-10 top-[3.2rem] text-sm font-sans box-border w-full  px-2 py-2 rounded-xl text-left bg-white ',
            'text-slate-900  transition-all hover:bg-slate-50 outline-0',
            'shadow-md shadow-slate-600 focus-visible:ring-4 ring-purple-500/30 focus-visible:border-purple-500',
            '[&>svg]:text-base	[&>svg]:absolute [&>svg]:h-full [&>svg]:top-0 [&>svg]:right-2.5',
            'shadow-sm shadow-primary-200 focus-within:shadow-md focus-within:shadow-primary-500/50 hover:border-primary-500'
          )}
        >
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option.value)}
              className={clsx(
                'cursor-pointer  z-10 ',
                'hover:bg-primary-200/40',
                `text-sm font-sans py-2 px-2 mb-1 w-full rounded-xl overflow-auto outline-0 top-0  text-slate-900
                 [.open_&]:opacity-100 [.open_&]:scale-100 transition-[opacity,transform] [.closed_&]:opacity-0 [.closed_&]:scale-90 
                 [.placement-top_&]:origin-bottom [.placement-bottom_&]:origin-top`,
                `${
                  selected == option.value
                    ? 'bg-primary-500 font-bold	 text-white hover:text-slate-500'
                    : ' bg-white'
                }`
              )}
            >
              {option.key}
            </li>
          ))}
        </ul>
      )}
      {error && <p className='text-sm text-red-500 mt-1'>{helperText}</p>}
    </div>
  );
};

export default Select;
