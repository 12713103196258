import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Typography } from '@mui/material';
import Btn from '../Components/common/Btn';
import { useAuth } from '../hooks/useAuth';

const RejectedApp = () => {
  const { logOut } = useAuth();
  return (
    <article className='w-80 md:w-96'>
      <section className='flex items-center gap-2 mb-8'>
        <HighlightOffIcon style={{ fontSize: '3rem' }} />
        <Typography variant='h5' fontWeight={500} component='h2'>
          No pudimos aprobar tu crédito
        </Typography>
      </section>
      <p className='mb-4'>
        Entendemos que esta noticia puede ser decepcionante y nos disculpamos
        por cualquier inconveniente que esto pueda causarte.
      </p>
      <p>
        Para brindarte más detalles, te hemos enviado información adicional a tu
        WhatsApp.
      </p>

      <div className='w-full mt-6'>
        <Btn
          type='submit'
          onClick={logOut}
          color='secondary'
          className=' w-full mb-4 '
        >
          <span className='font-bold'>Salir</span>
        </Btn>
      </div>
    </article>
  );
};

export default RejectedApp;
