import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import store from "../store";
import { resetAuth, selectStatus, setAuth } from "../store/auth";
import { clearSteps } from "./useApplicationSteps";
import { useNavigate } from "react-router-dom";
import { reset } from "../store/createProfile";

const handleLogout = () => {
  store.dispatch(resetAuth());
  sessionStorage.clear();
  clearSteps();
};

export const useAuth = () => {
  const navigate = useNavigate();

  const loginStatus = useSelector(selectStatus);

  const validateLogin = () => {
    const token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null;
    if (token !== null) {
      store.dispatch(
        setAuth({
          token,
          user,
          status: "authenticated",
        })
      );
    } else {
      store.dispatch(resetAuth());
    }
  };
  const logOut = () => {
    store.dispatch(reset());
    handleLogout();
    navigate("/newlogin");
  };

  return {
    isAuthenticated: loginStatus == "authenticated",
    validateLogin,
    logOut,
    loginStatus,
  };
};

export const useRefreshToken = () => {
  const navigate = useNavigate();
  const loginStatus = useSelector(selectStatus);

  useEffect(() => {
    const getToken = async () => {
      if (loginStatus == "authenticated") {
        const uri = `${process.env.REACT_APP_WELLI_API}/api/accounts/token/refresh`;
        const { data } = await axios.get(uri);
        if (data.token) {
          sessionStorage.setItem("token", data.token);
          store.dispatch(
            setAuth({
              token: data.token,
              status: "authenticated",
              user: true,
            })
          );
        } else {
          handleLogout();
          navigate("/newlogin");
        }
      }
    };
    const interval = setInterval(() => {
      getToken();
    }, 840000);

    return () => {
      clearInterval(interval);
    };
  });
};
