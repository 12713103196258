import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../Components/common/Input';
import { useDebounced } from '../../hooks/useDebounced';
import { useDoctors } from '../../hooks/debouncedDoctors';
import AutocompleteV1 from '../../Components/common/AutoComplete';
const url = 'assets/img/Welli_Logo-06.jpg';
import Btn from '../../Components/common/Btn';
import { useProceduresDebounced } from '../../hooks/useProcedureDebounced';
import {
  COUTES_DEFAULT,
  MAX_AMOUNT_VALUE,
  MIN_AMOUNT_VALUE,
} from '../../core/enums/credit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAmortization } from '../../hooks/useAmortization';
import dayjs from 'dayjs';
import store from '../../store';
import { handleNext } from '../../store/applicationStep';
import axios from 'axios';
import Modal from '../../Components/common/Modal/Modal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { currencyFormat } from '../../helpers/formaHelper';
import RejectedApp from '../../Shared/RejectedApp';
import AppInProcess from '../../Shared/AppInProcess';
import CampaignIcon from '@mui/icons-material/Campaign';

const CreateApplication = () => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [input, setInput] = useState('');
  const [inputProcedure, setInputProcedures] = useState('');
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const debouncedValue = useDebounced(input, 800);
  const { loadingDoctors, options, registerByUrl } = useDoctors(
    debouncedValue,
    setInput,
    setValue
  );
  const { loadingProcedures, options: optionsProcedures } =
    useProceduresDebounced(inputProcedure);

  const { laodAmor } = useAmortization();

  const onSubmit = (data) => {
    const profile_id = sessionStorage.getItem('profile_id');
    setShowModalProcess(true);

    const body = {
      ...data,
      fecha_procedimiento: dayjs(data.fecha_procedimiento).format('YYYY-MM-DD'),
      profile_id: profile_id,
      amortizacion: JSON.stringify(laodAmor),
      cuotas: parseInt(COUTES_DEFAULT),
    };
    const url = `${process.env.REACT_APP_WELLI_API}/api/applications/add`;

    axios
      .post(url, body)
      .then((res) => {
        if (res.data.estado === 'rejected') {
          setShowRejectedModal(true);
          return;
        }
        store.dispatch(handleNext(1));
      })
      .catch(function () {})
      .finally(() => setShowModalProcess(false));
  };

  return (
    <section className=' w-full flex h-full  flex-col content-center items-center justify-center bg-[#EDEEF8]'>
      <section className=' overflow-x-hidden w-full flex  flex-col  items-center px-8 py-8  md:px-12 md:pt-10 '>
        <img
          src={url}
          alt='Welli postura'
          className='hidden md:block w-24 mb-6 '
        />

        <section className='flex flex-col justify-center md:w-[34rem]'>
          <section className='mb-4'>
            <Typography gutterBottom variant='h6' component='h2'>
              Información del procedimiento médico
            </Typography>
          </section>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col gap-6 '
          >
            <div>
              <AutocompleteV1
                options={options}
                inputValue={input}
                loading={loadingDoctors}
                loadingText={'Buscando...'}
                placeholder='Profesional médico o institución'
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.nombre_comercial}
                key={(option) => option.id}
                onInputChange={(event, value) => {
                  if (!registerByUrl) {
                    setInput(value);
                    //trigger('medico'); // Dispara la validación
                  }
                }}
                inputRef={register('medico', { required: true })}
                onChange={(event, value) => {
                  if (value) {
                    setValue('medico_id', value.id, { shouldValidate: true });
                    setValue('medico', value.nombre_comercial, {
                      shouldValidate: true,
                    });
                  } else {
                    setValue('medico_id', '', { shouldValidate: true });
                    setValue('medico', '', { shouldValidate: true });
                  }
                }}
                error={errors.medico}
                helperText='El médico es obligatorio'
              />
            </div>

            <AutocompleteV1
              options={optionsProcedures}
              inputValue={inputProcedure}
              loading={loadingProcedures}
              loadingText={'Buscando...'}
              filterOptions={(x) => x}
              placeholder='Procedimiento'
              isOptionEqualToValue={(option, value) => option.id === value.id}
              inputRef={register('procedimiento', { required: true })}
              getOptionLabel={(option) =>
                `${option.speciality_name} - ${option.name}`
              }
              onInputChange={(event, value) => {
                setInputProcedures(value);
              }}
              onChange={(event, value) => {
                if (value) {
                  setValue(
                    'procedimiento',
                    `${value.speciality_name} - ${value.name}`,
                    {
                      shouldValidate: true,
                    }
                  );
                } else {
                  setValue('procedimiento', '', {
                    shouldValidate: true,
                  });
                }
              }}
              error={errors.procedimiento}
              helperText='El procedimiento es obligatorio'
            />
            <div>
              <Controller
                name='monto'
                control={control}
                rules={{
                  required: true,
                  min: MIN_AMOUNT_VALUE,
                  max: MAX_AMOUNT_VALUE,
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder='¿Cuánto dinero necesitas?'
                    isCurrency
                    error={Boolean(errors.monto)}
                    helperText={errors.monto?.message}
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('monto', value, { shouldValidate: true });
                    }}
                  />
                )}
              />
              {errors.monto && (
                <p className='text-sm text-red-500 mt-1'>
                  {errors.monto.type === 'required' &&
                    'El costo estimado es requerido'}
                  {errors.monto.type === 'max' &&
                    `El costo no puede superar los ${currencyFormat(
                      MAX_AMOUNT_VALUE
                    )}`}
                  {errors.monto.type === 'min' &&
                    `El costo no puede ser inferior a los ${currencyFormat(
                      MIN_AMOUNT_VALUE
                    )}`}
                </p>
              )}
            </div>

            <section className='mt-1 mb-0'>
              <Typography variant='h6' component='h2'>
                Campos no obligatorios
              </Typography>
            </section>

            <section className='flex w-full items-center gap-4'>
              <Input
                className=''
                {...register('codigo_descuento')}
                aria-label='Descuento'
                placeholder='Código de descuento'
              />
              <HelpOutlineIcon className='text-slate-400 ' />
            </section>

            <section className='flex w-full items-center gap-4'>
              <Input
                className=''
                {...register('codigo_asesor')}
                aria-label='Cod Asesor'
                placeholder='Código del asesor comercial'
              />
              <HelpOutlineIcon className='text-slate-400 ' />
            </section>

            <section>
              <Btn
                type='submit'
                onClick={() => {}}
                color='secondary'
                className=' w-full mb-2 '
              >
                <span className='w-[90%]'>Aplicar al crédito</span>
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 30 }}
                  className='right-3 rotate-180 '
                />
              </Btn>
            </section>
            <section className='flex gap-6'>
              <CampaignIcon sx={{ fontSize: 30 }} className='right-3' />
              <p className='font-bold '>
                No actualices la página mientras estudiamos tu solicitud
              </p>
            </section>
          </form>
        </section>
      </section>
      <Modal open={showRejectedModal} showClose={false}>
        <RejectedApp />
      </Modal>

      <Modal open={showModalProcess} showClose={false}>
        <AppInProcess />
      </Modal>
    </section>
  );
};

export default CreateApplication;
