import React from 'react';
import Btn from '../../../Components/common/Btn';
const ErrorLogin = ({ errorLogin, type, onCancel, phone }) => {
  return (
    <section className='flex flex-col items-center'>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyCcontent: 'center',
        }}
        className='mb-8'
      >
        <img
          className='w-6 h-6 mr-2 rounded-full object-cover object-center'
          src='/assets/img/Flag_Colombia.jpg'
        />
        <span className='font-bold text-lg'>+57 {phone}</span>
      </div>
      {errorLogin && type == 'continue' && (
        <section className='flex aling-center items-center flex-col w-80'>
          <p className='text-lg text-center font-bold'>
            El celular aún no se ha registrado.
          </p>
          <h3 className='text-lg mb-8 text-center'>
            Por favor, verifica que el número sea correcto
          </h3>

          <Btn
            variant='outlined'
            size='medium'
            color='white'
            className=' relative w-80  bg-transparent'
            onClick={onCancel}
          >
            Volver y editar número
          </Btn>
        </section>
      )}
    </section>
  );
};

export default ErrorLogin;
