import React, { useState, useRef, useEffect } from 'react';
import FastProcess from './sliders/FastProcess';
import RelaxProcess from './sliders/RelaxProcess';
import SecureProcess from './sliders/SecureProcess';

const Slider = ({ showControls, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      id: 1,
      image: 'https://via.placeholder.com/800x400?text=Slide+1',
      Component: FastProcess,
    },
    {
      id: 2,
      image: 'https://via.placeholder.com/800x400?text=Slide+2',
      Component: RelaxProcess,
    },
    {
      id: 3,
      image: 'https://via.placeholder.com/800x400?text=Slide+3',
      Component: SecureProcess,
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  // Función para arrastre manual
  const handleDragStart = (e) => {
    sliderRef.current.dragStartX = e.clientX || e.touches[0].clientX;
  };

  const handleDragEnd = (e) => {
    const dragEndX = e.clientX || e.changedTouches[0].clientX;
    const dragDistance = sliderRef.current.dragStartX - dragEndX;

    if (dragDistance > 50) handleNext();
    if (dragDistance < -50) handlePrev();
  };

  // Cambiar automáticamente el slider cada X segundos
  useEffect(() => {
    const intervalId = setInterval(handleNext, interval);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [currentIndex, interval]);

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        height: '100%',
      }}
      className='rounded-b-3xl  overflow-hidden shadow-md'
    >
      {/* Slider container */}
      <div
        ref={sliderRef}
        style={{
          display: 'flex',
          height: '100%',
          transition: 'transform 0.4s ease-in-out',
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onTouchStart={handleDragStart}
        onTouchEnd={handleDragEnd}
      >
        {slides.map(({ id, Component }) => (
          <Component key={id} />
        ))}
      </div>

      {/* Controles */}
      {showControls && (
        <>
          <button
            onClick={handlePrev}
            style={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            {'<'}
          </button>
          <button
            onClick={handleNext}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
          >
            {'>'}
          </button>
        </>
      )}

      {/* Dots */}
      <div className='w-full px-6 py-4 absolute top-0 z-20  mt-2'>
        <div className='w-full flex justify-around'>
          {slides.map((_, index) => (
            <div
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`cursor-pointer mx-1 w-20 h-1 ${
                index === currentIndex ? 'bg-white' : 'bg-gray-500'
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
