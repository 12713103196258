import { configureStore } from '@reduxjs/toolkit'
import profileByUserReducer from './profileByUser'
import applicationsReducer from './applications'

import applicationStepReducer from './applicationStep'
import authStore from './auth'
import loan from './loan'
import createProfileReducer from './createProfile'

export default configureStore({
    reducer: {
        profileByUser: profileByUserReducer,
        applications: applicationsReducer,
        auth: authStore,
        apllicationStep: applicationStepReducer,
        loanReducer: loan,
        createProfile: createProfileReducer
    }
})
