import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const variantClasses = {
  contained: {
    primary: 'bg-blue-500 text-white hover:bg-blue-600 shadow-indigo-500/40 ',
    secondary: 'bg-secondary-500 text-white hover:bg-secondary-600',
    danger: 'bg-red-500 text-white hover:bg-red-600',
    white: 'bg-white border border-gray-700 text-gray-700 hover:bg-gray-200',

  },
  outlined: {
    primary: 'border border-blue-500 text-blue-500 hover:bg-blue-100',
    secondary: 'border border-gray-500 text-gray-500 hover:bg-gray-100',
    danger: 'border border-red-500 text-red-500 hover:bg-red-100',
    white: 'border border-gray-700 text-gray-700 hover:bg-gray-200 ',
  },
  text: {
    primary: 'text-gray-900 bg-none border-none shadow-none  hover:shadow-lg  ',
    secondary: 'text-gray-500 hover:bg-gray-100',
    danger: 'text-red-500 hover:bg-red-100',
  },
};

const sizeClasses = {
  small: 'px-3 py-2 text-sm',
  medium: 'px-4 py-3 text-base',
  large: 'px-6 py-3 text-lg',
};

const CustomButton = (
  {
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    className,
    ...props
  },
) => {

  return (
    <button
      className={clsx(
        `
        flex items-center justify-center gap-2
        cursor-pointer shadow-md transition  font-semibold border-2 focus:outline-none focus:ring-2 focus:ring-offset-2,
        rounded-3xl shadow-sm,
        `,
        sizeClasses[size],
        variantClasses[variant]?.[color], 
        `disabled:text-slate-500  disabled:bg-slate-200 ,
         disabled:cursor-default disabled:shadow-none
         disabed:border-0
         disabled:border-transparent
        `,
        className
      )}
      {...props}
    />
  );
};
CustomButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default CustomButton;
