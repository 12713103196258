import { useEffect, useState } from 'react';
import { getAmortization } from '../api/amortization';

export const useAmortization = () => {
  const [laodAmor, setLaodAmor] = useState();

  useEffect(() => {
    getAmortization()
      .then((resp) => {
        setLaodAmor(resp.data);
      })
      .catch((error) => console.log('error', error));
  }, []);

  return { laodAmor };
};
