import React, { useEffect, useState } from 'react';
import Actions from './Actions';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../Components/common/Input';
import { Typography } from '@mui/material';
import Select from '../../../Components/common/Select';
import { ID_TIPOS } from '../../../core/enums/generic';
import DatePicker from '../../../Components/common/DatePicker';
import dayjs from 'dayjs';
import { getDiffYearsCurrentDate } from '../../../helpers/form-helpers';
import { MAX_AGE_TO_APPLY } from '../../../core/enums/credit';
const url = 'assets/img/Welli_Logo-06.jpg';
import './field.css';
import store from '../../../store';
import {
  fetchCreateApp,
  selectFetching,
  selectFormData,
  setData,
} from '../../../store/createProfile';
import { useSelector } from 'react-redux';
import { handleNext } from '../../../store/applicationStep';
const maxDate = dayjs().subtract(18, 'years').toDate();
const minDate = dayjs().subtract(110, 'years').toDate();

const validateDate = (e) => {
  const value = e;
  if (!value) {
    return 'La fecha de nacimiento es requerida';
  }

  if (!dayjs(value).isValid()) {
    return 'La fecha de nacimiento no es válida (debe ser en formato mm/dd/aaaa)';
  }
  const diffYears = getDiffYearsCurrentDate(value).diffYears;
  if (diffYears < 18) {
    return 'Debe ser mayor de 18 años';
  }

  if (diffYears && diffYears > MAX_AGE_TO_APPLY) {
    return `La edad máxima para aplicar es ${MAX_AGE_TO_APPLY} años`;
  }

  return true;
};

const Step2 = ({ handleGoBack }) => {
  const {
    register,
    getValues,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipo_documento: ID_TIPOS[0].value,
    },
  });

  const [isFocusID, setisFocusID] = useState(false);

  const apiRequest = useSelector(selectFetching);

  const formData = useSelector(selectFormData);

  useEffect(() => {
    if (formData) {
      Object.entries(formData).forEach(([key, value]) => {
        if (value) {
          if (key == 'fecha_nacimiento') {
            setValue(key, dayjs(value).toISOString());
          } else {
            setValue(key, value);
          }
        }
      });
    }
  }, [formData]);

  useEffect(() => {
    if (apiRequest.fetching === 'succeeded' && apiRequest.apiData) {
      sessionStorage.setItem('profile_id', apiRequest.apiData.id);
      store.dispatch(handleNext(1));
    }
  }, [apiRequest]);

  const onSubmit = () => {
    const values = getValues();
    const data = {
      ...formData,
      ...values,
      fecha_nacimiento: dayjs(values.fecha_nacimiento).format('YYYY-MM-DD'),
    };
    delete data.documento2;
    delete data.email2;
    store.dispatch(fetchCreateApp(data));
  };

  const goBack = () => {
    const data = { ...getValues() };
    if (dayjs(data.fecha_nacimiento).isValid()) {
      data.fecha_nacimiento = dayjs(data.fecha_nacimiento).toISOString()
    }else{
      data.fecha_nacimiento = ''
    }
    store.dispatch(setData(data));
    handleGoBack();
  };

  return (
    <section className=' overflow-x-hidden w-full flex  flex-col  items-center px-8 py-8  md:px-12 md:pt-10 '>
      <img
        src={url}
        alt='Welli postura'
        className='hidden md:block w-24 mb-6 '
      />
      <form onSubmit={handleSubmit(onSubmit)} className='w-full md:w-[34rem]'>
        <section className='mb-4'>
          <Typography gutterBottom variant='h6' component='h2'>
            Fecha de Nacimiento
          </Typography>

          <DatePicker
            inputProps={{
              ...register('fecha_nacimiento', {
                required: 'La fecha de nacimiento es requerida',
                validate: validateDate,
              }),
            }}
            value={watch('fecha_nacimiento')}
            onChange={(newValue) => {
              setValue('fecha_nacimiento', newValue, { shouldValidate: true });
            }}
            maxDate={maxDate}
            minDate={minDate}
          ></DatePicker>

          {errors.fecha_nacimiento && (
            <span className='text-red-500 text-sm'>
              {errors.fecha_nacimiento.message}
            </span>
          )}
        </section>
        <section className='mb-8 flex flex-col justify-center  gap-6'>
          <Typography gutterBottom variant='h6' component='h2'>
            Información del documento de identidad
          </Typography>

          <Controller
            name='tipo_documento'
            control={control}
            rules={{ required: 'El tipo de documento es requerido' }}
            render={({ field }) => (
              <Select
                valueInitial={field.value}
                placeholder='Seleccione tipo de documento'
                options={ID_TIPOS}
                error={Boolean(errors.actividad_economica)}
                helperText={errors.tipo_documento?.message}
                onChange={field.onChange}
              ></Select>
            )}
          />

          <div>
            {!isFocusID == 'open'}
            <Input
              className='w-full'
              onFocus={() => setisFocusID(true)}
              isPassword
              showPassword={isFocusID}
              onTogglePassword={(value) => setisFocusID(value)}
              placeholder='Número Documento'
             
              name='documento'
              type='number'
              autoComplete='numero-documento'
              {...register('documento', {
                required: 'El número de documento es requerido',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Ingresa datos numéricos',
                },
                onBlur: () => setisFocusID(false),
              })}
              error={Boolean(errors.documento)}
              helperText={errors.documento?.message}
            />
          </div>
          <div>
            <Input
              className='w-full'
              placeholder='Confirmar Número Documento'
              name='documento2'
              onPaste={(e) => e.preventDefault()}
              {...register('documento2', {
                required: 'Debes confirmar el número de documento',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Ingresa datos numéricos',
                },
                validate: (value) =>
                  value === watch('documento') ||
                  'Los números de documentos no coinciden',
              })}
              error={Boolean(errors.documento2)}
              helperText={errors.documento2?.message}
            />
          </div>
        </section>
        {apiRequest.fetching == 'failed' && (
          <div className='my-4'>
            <Typography variant='body2' color='red' align='justify'>
              Ha ocurrido un error al crear el perfil
            </Typography>
          </div>
        )}
        <Actions handleGoBack={goBack} />
      </form>
    </section>
  );
};

export default Step2;
