import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../Components/common/Input';
import { EMAIL_PATTERN } from '../../../core/enums/regxs';
import { ENCONOMIC_ACTIVITY } from '../../../core/enums/generic';
import Select from '../../../Components/common/Select';
import Actions from './Actions';
import { selectFormData, setData } from '../../../store/createProfile';
import store from '../../../store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const url = 'assets/img/Welli_Logo-06.jpg';

const Start = ({ nextStep }) => {
  const formData = useSelector(selectFormData);
  const navigate = useNavigate();
  const {
    register,
    getValues,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (formData) {
      Object.entries(formData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [formData]);

  const onSubmit = () => {
    const data = { ...getValues() };
    store.dispatch(setData(data));
    nextStep();
  };

  const handleGoBack = () => {
    navigate('/create_account');
  };
  return (
    <section className='overflow-x-hidden w-full flex  flex-col  items-center px-8 py-8  md:px-12 md:pt-10 '>
      <img
        src={url}
        alt='Welli postura'
        className='hidden md:block w-24 mb-6 '
      />

      <section className='flex flex-col justify-center md:w-[34rem]'>
        <section className='mb-4'>
          <h2 className='text-2xl font-bold mb-2'>
            Iniciemos la aplicación del crédito
          </h2>
          <Typography gutterBottom component='h6'>
            Asegúrate que la información sea correcta. De lo contrario podría
            impactar la aprobación del crédito
          </Typography>
        </section>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-6 '
        >
          <div>
            <Input
              className='w-full'
              {...register('nombres', {
                required: 'El Nombre es obligatorio',
              })}
              aria-label='Name'
              placeholder='Nombre'
              error={Boolean(errors.nombres)}
              helperText={errors.nombres?.message}
            />
          </div>

          <div>
            <Input
              className='w-full'
              {...register('apellidos', {
                required: 'El Apellido es obligatorio',
              })}
              aria-label='Primer apellido'
              placeholder='Primer apellido'
              error={Boolean(errors.apellidos)}
              helperText={errors.apellidos?.message}
            />
          </div>

          <div>
            <Input
              className='w-full'
              {...register('email', {
                required: 'El correo electrónico es requerido',
                pattern: {
                  value: EMAIL_PATTERN,
                  message: 'Ingresa un formato válido de correo electrónico',
                },
                onChange: (event) => {
                  setValue(
                    'email',
                    event.target.value.toLowerCase().replace(/\s+/g, '')
                  );
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              aria-label='Correo Electrónico'
              placeholder='Correo Electrónico'
            />
          </div>

          <div>
            <Input
              className='w-full'
              placeholder='Confirmación Correo Electrónico'
              name='email2'
              onPaste={(e) => e.preventDefault()}
              autoComplete='email'
              {...register('email2', {
                required: 'Debes confirmar el correo electrónico',
                pattern: {
                  value: EMAIL_PATTERN,
                  message: 'Ingresa un formato válido de correo electrónico',
                },
                onChange: (event) => {
                  setValue(
                    'email2',
                    event.target.value.toLowerCase().replace(/\s+/g, '')
                  );
                },
                validate: (value) =>
                  value === watch('email') ||
                  'Los correos electrónicos no coinciden',
              })}
              error={Boolean(errors.email2)}
              helperText={errors.email2?.message}
            />
          </div>

          <div>
            <Controller
              name='actividad_economica'
              control={control}
              rules={{ required: 'La actividad económica es requerida' }}
              render={({ field }) => (
                <Select
                  valueInitial={field.value}
                  placeholder='Seleccione actividad económica'
                  options={ENCONOMIC_ACTIVITY}
                  error={Boolean(errors.actividad_economica)}
                  helperText={errors.actividad_economica?.message}
                  onChange={field.onChange}
                ></Select>
              )}
            />
          </div>
          <Input
            className='w-full'
            type='number'
            {...register('telefono_contacto', {
              pattern: {
                value: /^3\d{9}$/,
                message: 'El número de celular no es válido',
              },
              maxLength: {
                value: 10,
                message:
                  'El número de celular no debe ser mayor de 10 caracteres',
              },
              minLength: {
                value: 10,
                message:
                  'El número de celular no debe ser menor de 10 caracteres',
              },
            })}
            aria-label='Phone'
            placeholder='Otro número de contacto (opcional)'
          />
          {errors.telefono_contacto && (
            <p className='text-sm text-red-500 '>
              {errors.telefono_contacto.message}
            </p>
          )}
          <Actions handleGoBack={handleGoBack} />
        </form>
      </section>
    </section>
  );
};

export default Start;
