import { Typography } from '@mui/material';
import React from 'react';
import Btn from '../../../Components/common/Btn';

const InProgress = ({logout}) => {
  return (
    <div className='flex flex-col w-80 md:w-96'>
      {' '}
      <Typography id='modal-modal-title' variant='h6' component='h2'>
        ¡Hola! Gracias por aplicar a tu crédito a través de Welli.
      </Typography>
      <Typography id='modal-modal-description' sx={{ mt: 2 }}>
        Tu solicitud está en proceso, recibirás pronto respuesta en el email
        registrado con nosotros o un asesor de welli.
        <br />
        <br />
        Si necesitas asesoría adicional escribenos a:
        <br />
        <br />
        <span className='font-bold'>
          servicioalcliente@welli.com.co <br />
          Whatsapp +57 310-5240287.
        </span>
      </Typography>
      <Btn onClick={logout} color='danger' className='relative w-full mt-4'>
        Salir
      </Btn>
    </div>
  );
};

export default InProgress;

/* 


*/
