import axios from 'axios';
import { useEffect, useState } from 'react';

export const useDoctors = (debouncedValue, setInput, setValue) => {
  const [loadingDoctors, setLoadingDoctors] = useState(false);
  const [options, setOptions] = useState([]);
  const [registerByUrl, setRegisterByUrl] = useState(false);

  // Cargar datos desde sessionStorage en el montaje
  useEffect(() => {
    const loadFromSession = () => {
      try {
        const stored = JSON.parse(sessionStorage.getItem('registerByUrl'));
        if (stored?.medicoId) {
          setRegisterByUrl(stored);
          setInput(stored.medico);
        }
      } catch (error) {
        console.error('Invalid sessionStorage data:', error);
      }
    };
    loadFromSession();
  }, [setInput]);

  // Buscar doctores basados en el valor debounced
  useEffect(() => {
    if (!debouncedValue) {
      setOptions([]); // Limpiar las opciones si el valor es vacío
      return;
    }

    const fetchDoctors = async () => {
      setLoadingDoctors(true);
      try {
        const uri = `${
          process.env.REACT_APP_WELLI_API
        }/api/doctors/search?nombre=${encodeURIComponent(debouncedValue)}`;
        const resp = await axios.get(uri);
        const data = resp.data.data;
        setOptions(data.length ? data : []);

        // Verificar si hay un médico coincidente desde la URL
        if (registerByUrl?.medicoId) {
          const found = data.find((m) => m.id === registerByUrl.medicoId);
          if (found) {
            setValue('medico_id', registerByUrl.medicoId);
            setValue('medico', found.nombre_comercial);
          }
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
        setOptions([]);
      } finally {
        setLoadingDoctors(false);
      }
    };

    fetchDoctors();
  }, [debouncedValue, setValue, registerByUrl]);

  return { loadingDoctors, options, registerByUrl };
};
