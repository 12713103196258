import { Box, Typography } from '@mui/material';
import React from 'react';
import Buttom from '../Components/common/Btn';
import { useAuth } from '../hooks/useAuth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AppRiskInProccess = () => {
  const { logOut } = useAuth();

  return (
    <div>
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          ¡Hola! Gracias por aplicar a tu crédito a través de Welli.
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          Tu solicitud está en proceso, recibirás pronto respuesta en el email
          registrado con nosotros o un asesor de welli.
          <br />
          <br />
          Si necesitas asesoría adicional escribenos a
          servicioalcliente@welli.com.co ó al Whatsapp +57 310-5240287.
        </Typography>
        <Buttom
          type='submit'
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
          onClick={logOut}
        >
          SALIR
        </Buttom>
      </Box>
    </div>
  );
};

export default AppRiskInProccess;
