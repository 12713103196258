import React from 'react';

const SecureProcess = () => {
  return (
    <div
      className=' w-full shrink-0 h-full  flex flex-col items-center justify-center '
      style={{
        background:
          'linear-gradient(260deg, rgba(5,213,189,1) 0%, rgba(76,125,255,1) 100%)',
      }}
    >
      <img
        src={'assets/img/sellos-07.png'}
        alt='Welli postura'
        className=' h-[70%] md:h-[80%] text-center mt-10'
        draggable="false"
      />
    </div>
  );
};

export default SecureProcess;
