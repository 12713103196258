import React, { useState } from 'react';
import '../../../Shared/css/animations.css'; // A
import Start from '../components/Start';
import Step2 from '../components/Step2';

const CreateProfile = () => {
  const [showOptions, setShowOptions] = useState(true);
  const toggleComponent = () => {
    setShowOptions(!showOptions);
  };

  return (
    <section className=' overflow-hidden w-full flex  flex-col content-center items-center justify-center bg-[#EDEEF8]'>
      {showOptions && (
        <div className='animate-fadeIn flex flex-col w-full  gap-8 md:gap-6'>
          <Start nextStep={toggleComponent} />
        </div>
      )}

      {!showOptions && (
        <div className='animate-fadeIn  w-full'>
          <Step2 handleGoBack={() => toggleComponent()} />
        </div>
      )}
    </section>
  );
};

export default CreateProfile;
