import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useApplicationSteps from "../hooks/useApplicationSteps";
import { selectActiveStep } from "../store/applicationStep";
import { useSelector } from "react-redux";

export default function Home() {
  const step = useSelector(selectActiveStep);
  useApplicationSteps()
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("token") == null) {
      navigate("/newlogin");
    }
    const id = sessionStorage.getItem("id");

    axios
      .get(`${process.env.REACT_APP_WELLI_API}/profile/by_user/${id}`)
      .then((resp) => {
        sessionStorage.setItem("user_id", resp.data.user_id);
        //const user_id = resp.data.user_id;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    step <= 4 && navigate("/profileform");
  }, [step]);

  return <div></div>;
}
