import axios from 'axios';
import { useState } from 'react';
import {
  ACCESS_DASHBOARD_ENABLED,
  availableAccessDashboardStatus,
  unavailableAnyAccess,
  validatingAPPStatus,
} from '../core/enums/appStatus';
import store from '../store';
import { setAuth } from '../store/auth';
import { clearSteps } from './useApplicationSteps';

const sendOptLogin = async ({ phone }) => {
  try {
    await axios
    .get(`${process.env.REACT_APP_WELLI_API}/api/accounts/login/phone/${phone}`);
    return true
  } catch (error) {
    return false;
    
  }
};

const useLogin = (navigate) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inValidationApp, setInValidation] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showFailCodes, setShowFailCodes] = useState(false);




  const sentToNavigate = (token, url, user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    clearSteps();
    store.dispatch(setAuth({ user, token, status: "authenticated" }));
    navigate(url);

  };


  

  const login = async (phone, otp) => {
    setIsLoading(true);

    const body = {
      otp,
      platform: 'sms',
      value: phone.includes('57') ? phone : `57${phone}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WELLI_API}/api/accounts/login`,
        body
      );

      const { data } = response;
      sessionStorage.setItem('id', data.id);
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('profile_id', data.profile_id);

      if (data.profile_id == null) {
        sentToNavigate(data.token, '/create_profile', null);
        return;
      }

      const user = {
        telefono: data.telefono,
        email: data.email,
        nombres: data.nombres,
        apellidos: data.apellidos,
      };

      if (!data?.estado_app) {
        sentToNavigate(data.token, '/application', user);
        return;
      }

      const estado = data?.estado_app;
      const credito = data?.credito;

      if (credito && availableAccessDashboardStatus.includes(estado)) {
        if (ACCESS_DASHBOARD_ENABLED) {
          sessionStorage.setItem('loan', credito);
          sentToNavigate(data.token, '/dashboard', user);
        } else {
          setInValidation(true);
        }
        return;
      }

      if (validatingAPPStatus.includes(estado)) {
        setInValidation(true);
        return;
      }

      if (unavailableAnyAccess.includes(estado)) {
        setShowRejectedModal(true);
        return;
      }

      sentToNavigate(data.token, '/application', user);
    } catch (error) {
      setShowFailCodes(true);
      console.error('Error in login:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    login,
    isLoading,
    inValidationApp,
    setInValidation,
    showRejectedModal,
    setShowRejectedModal,
    showFailCodes,
    setShowFailCodes,
  };
};

const reeSendOtpValidationAccount = () => {
  const [fetchingValidation, setFetchingValidation] = useState('idle');

  const sendOptValAccount = async (phone) => {
    axios
      .get(`${process.env.REACT_APP_WELLI_API}/api/accounts/retry/sms/${phone}`)
      .then(() => {
        setFetchingValidation('success');
      })
      .catch(() => {
        setFetchingValidation('error');
      });
  };

  return { sendOptValAccount, fetchingValidation };
};

const validateAccount = (navigate) => {
  const [fetchingValidateAccont, setFetchingValidateAccont] = useState('idle');
  const handleValidation = ({phone, otp}) => {
    const url = `${process.env.REACT_APP_WELLI_API}/api/accounts/confirm/sms/${phone}/${otp}`;

    axios
      .post(url)
      .then((resp) => {
        sessionStorage.setItem('token', resp.data.token);
        sessionStorage.setItem('id', resp.data.id);
        store.dispatch(
          setAuth({
            user: true,
            token: resp.data.token,
            status: 'authenticated',
          })
        );
        setFetchingValidateAccont('success');
        navigate('/application');
      })
      .catch(() => {
        setFetchingValidateAccont('rejected');
      });
  };

  return { fetchingValidateAccont, handleValidation };
};
export { sendOptLogin, useLogin, reeSendOtpValidationAccount, validateAccount };
