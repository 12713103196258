import * as React from 'react';
import clsx from 'clsx';
import './modal.css';

const Modal = ({ open, onClose, showClose = true, children }) => {
  return (
    <>
      {open && (
        <div className='bg-black/45 fixed w-screen h-screen top-0 left-0 '></div>
      )}

      <dialog open={open} className='rounded-xl shadow-2xl top-1/4'>
        <div className={clsx('w-fit')}>
          {showClose && (
            <button
              type='button'
              onClick={onClose}
              className={clsx(
                'absolute top-2 right-2 text-gray-500 hover:text-gray-800'
              )}
            >
              ✕
            </button>
          )}

          {children}
        </div>
      </dialog>
    </>
  );
};

export default Modal;
