import React, { useState } from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const resolveSlotProps = (fn, args) =>
  typeof fn === 'function' ? fn(args) : fn;

const Input = React.forwardRef(
  (
    {
      error,
      helperText,
      className,
      slotProps,
      startAdornment,
      endAdornment,
      label,
      readOnly,
      cursorPointer,
      isCurrency,
      isPassword,
      showPassword: propShowPassword,
      onTogglePassword,
      ...props
    },
    ref
  ) => {
    const [localShowPassword, setLocalShowPassword] = useState(false);

    // Usar showPassword si se pasa como prop, de lo contrario usar el estado local
    const showPassword = propShowPassword ?? localShowPassword;



    const handleValueChange = (values) => {
      if (props.onChange) {
        const { floatValue } = values;

        props.onChange({
          target: {
            name: props.name,
            value: floatValue,
          },
        });
      }
    };

    const togglePasswordVisibility = () => {
      if (onTogglePassword) {
        onTogglePassword(!showPassword);
      } else {
        setLocalShowPassword((prev) => !prev);
      }
    };

    return (
      <>
        {label && (
          <p className="text-gray-900">
            <span className="ml-2 text-gray-900">{label}</span>
          </p>
        )}
        <div
          className={clsx(
            'flex w-full items-center border rounded-xl overflow-hidden',
            'border-solid',
            'shadow-sm shadow-slate-200 focus-within:shadow-md',
            'bg-white text-primary-900',
            `${
              error
                ? 'border-red-500 focus-within:shadow-red-500/50 focus-within:border-red-500 hover:border-red-500'
                : 'border-slate-400 focus-within:shadow-primary-500/50 focus-within:border-primary-500 hover:border-primary-600'
            }`
          )}
        >
          {startAdornment && (
            <div className="pl-3 flex items-center justify-center">
              {startAdornment}
            </div>
          )}
          {isCurrency ? (
            <NumericFormat
              {...props}
              fixedDecimalScale
              prefix="$"
              className={clsx(
                className,
                'flex-1',
                'w-full text-sm font-normal font-sans leading-5 px-4 py-3',
                'focus-visible:outline-none',
                cursorPointer && 'cursor-pointer'
              )}
              getInputRef={ref}
              onValueChange={handleValueChange}
              onChange={() => {}}
              thousandSeparator
              valueIsNumericString
            />
          ) : (
            <BaseInput
              readOnly={readOnly}
              {...props}
              type={isPassword ? (showPassword ? 'text' : 'password') : props.type}
              className={clsx(className, 'flex-1')}
              ref={(node) => {
                if (ref) {
                  typeof ref === 'function' ? ref(node) : (ref.current = node);
                }
                if (props.inputRef) {
                  typeof props.inputRef === 'function'
                    ? props.inputRef(node)
                    : (props.inputRef.current = node);
                }
              }}
              slotProps={{
                ...slotProps,
                input: (ownerState) => {
                  const resolvedSlotProps = resolveSlotProps(
                    slotProps?.input,
                    ownerState
                  );
                  return {
                    ...resolvedSlotProps,
                    className: clsx(
                      'w-full text-sm font-normal font-sans leading-5 px-4 py-3',
                      'focus-visible:outline-none',
                      cursorPointer && 'cursor-pointer',
                      resolvedSlotProps?.className
                    ),
                  };
                },
              }}
            />
          )}
          {isPassword && (
            <div
              className="pr-3 flex items-center justify-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff className='text-gray-500' /> : <Visibility className='text-gray-500'/>}
            </div>
          )}
          {endAdornment && (
            <div
              className="pr-3 flex items-center justify-center"
              onClick={() => props?.onClick()}
            >
              {endAdornment}
            </div>
          )}
        </div>
        {error && <p className="text-sm text-red-500 mt-1">{helperText}</p>}
      </>
    );
  }
);

Input.displayName = 'Input';

export default Input;
